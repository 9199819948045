#root, .App {
  height: 100%;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  user-select: none;
}

.App {
  text-align: center;
  
  .app-header {
    background-color: yellow;
    padding-left: 0;
    h1 {
      font-weight: bolder;
      font-size: 20pt;

      img {
        height: 50px;
        float: left;
        margin: 6px;
      }
    }
  }

  .app-content {
    background-color: black;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: auto;
  }

  .app-sider {
    color: yellow;
  }
}