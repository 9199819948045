.starship-list {
  color: yellow;
  
  .ant-list-header {
    border-bottom: none !important;
    
    h2 {
      color: yellow;
      font-weight: bolder;
      font-size: 18pt;
      margin: 10px 0 20px 20px;
      text-align: left;
    }
    
    .header-row-2 {
      display: inline-block;
      
      button {
        height: 50px;
      }
      
      .ant-dropdown-button {
        width: 32px;
        button:first-child {
          display: none;
        }
      }

      .searchInput {
        width: calc(100% - 32px);

        input {
          background-color: rgba(256, 256, 256, 0.1);
          border: none;
          color: white;
          font-size: 14pt;
          padding: 10px 20px;
          height: 50px;
        }  
      }
    }
    
  }


  .starship-item {
    width: 100%;
    display: block;
    padding: 20px 10px;
    text-align: left;
    font-weight: bolder;
    font-size: 14pt;
    transition: linear 250ms background-color, linear 250ms color;

    &.hover {
      background-color: yellow;
      color: black;
    }
    
    .hits {
      padding: 0 20px;
      margin-right: 10px;
      background-color: #1890ff;
      display: inline-block;
      text-align: center;
      border-radius: 15px;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: initial;
  }

  // Scroller setup
  &, .ant-spin-nested-loading, .ant-spin-container, .ant-list-items {
    position: relative;
    height: 100%;
    &.ant-spin-nested-loading {
      height: calc(100% - 62px);
    }
    &.ant-list-items {
      overflow: auto;
    }
  }
}
